import React, { useEffect, useRef, useState, useCallback } from 'react';
import { 
  Card, 
  CardContent,
  CardHeader,
  Typography,
  Alert,
  Box,
  CircularProgress
} from '@mui/material';
import CloudOffIcon from '@mui/icons-material/CloudOff';

import RFB from '@novnc/novnc/lib/rfb'


const VNCViewer = ({ 
    url,
    authToken,
}) => {
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('Initializing');
    const [reconnectCount, setReconnectCount] = useState(0);
    const rfbRef = useRef(null);
    const canvasRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);
    
  const handleError = useCallback((message, err = null) => {
    setError(message);
    setStatus('Error');
  }, []);

  const connectVNC = useCallback(async () => {
    try {
      if (!canvasRef.current) {
        throw new Error('Canvas not ready');
      }
  
      if (rfbRef.current) {
        rfbRef.current.disconnect();
        rfbRef.current = null;
      }
    
      const wsOptions = {
        credentials: { password: '' },
        scaleViewport: true,
        backgroundColor: '#000000',
        qualityLevel: 6,
        compressionLevel: 2,
        wsProtocols: ['binary'],
        shared: true,
        keepaliveInterval: 5000,  // Send keepalive every 5 seconds
      };
  
      if (authToken) {
        wsOptions.headers = {
          'Authorization': `Bearer ${authToken}`
        };
      }
  
      rfbRef.current = new RFB(canvasRef.current, url, wsOptions);

      rfbRef.current.addEventListener('connect', () => {
        setStatus('Connected');
        setError(null);
        setReconnectCount(0);
      });

      rfbRef.current.addEventListener('credentialsrequired', () => {
        handleError('VNC authentication failed');
      });

      rfbRef.current.addEventListener('securityfailure', (e) => {
        handleError(`Security failure: ${e.detail.status}`);
      });
        
      rfbRef.current.addEventListener('disconnect', (e) => {
        setStatus('Disconnected');
      });

    } catch (err) {
      handleError(`Failed to connect to VNC server: ${err.message}`, err);
      throw err;
    }
  }, [url, authToken, handleError]); // Remove websocketPort from dependencies

  useEffect(() => {
    let mounted = true;

    const initialize = async () => {
      if (url) { // Remove vncPort check since it's not needed in frontend
        try {
          setStatus('Loading');
          await connectVNC();
        } catch (err) {
          if (mounted) {
            handleError('Failed to initialize VNC viewer', err);
          }
        }
      }
    };

      initialize();

    return () => {
      mounted = false;
      if (rfbRef.current) {
        rfbRef.current.disconnect();
        rfbRef.current = null;
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [url, connectVNC, handleError]); // Remove vncPort from dependencies

  return (
    <Card sx={{ maxWidth: '4xl', width: '100%', height: '100%' }}>
     
      <CardContent>
        {error && (
          <Alert 
            severity="error" 
            sx={{ mb: 2 }}
          >
            {error}
          </Alert>
        )}
        
        <Box 
          sx={{ 
            position: 'relative',
            aspectRatio: '1100/850',
            bgcolor: 'black',
            borderRadius: 1,
            overflow: 'hidden'
          }}
        >
          {(['Loading', 'Reconnecting'].includes(status)) && (
            <Box 
              sx={{
                position: 'absolute',
                inset: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                zIndex: 1
              }}
                      >
              <CircularProgress color="inherit" />
              <Typography>
                {status === 'Reconnecting' 
                  ? `Reconnecting (Attempt ${reconnectCount})...` 
                  : 'Connecting to browser...'}
              </Typography>
            </Box>
          )}
          {status === 'Disconnected' && (
            <Box 
              sx={{
                position: 'absolute',
                inset: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(128, 128, 128, 0.1)',
                zIndex: 1,
                gap: 2
              }}
            >
              <CloudOffIcon sx={{ fontSize: 48, color: 'grey.500' }} />
              <Typography color="grey.500">Disconnected from browser</Typography>
            </Box>
          )}
          <Box 
            ref={canvasRef}
            sx={{
              width: '100%',
              height: '100%'
            }}
            tabIndex={0}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            onContextMenu={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </Box>
        <Typography 
          variant="caption" 
          sx={{ 
            mt: 1, 
            display: 'block', 
            color: 'text.secondary' 
          }}
        >
          Connection Status: {status}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default VNCViewer;