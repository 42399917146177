import axios from 'axios';

export async function loadSessionExecutionData(sessionId) {
  if (!sessionId) {
    throw new Error("Session ID is required");
  }

  try {
    const response = await axios.get(
        `https://core.trykura.com/api/load_session_execution_data/${sessionId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
} 