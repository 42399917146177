import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from './Navigation';
import VNCViewer from './BrowserViewer';
import { loadSessionExecutionData } from '../services/executionApi';

const AgentViewer = () => {


  const [decodedUrl, setDecodedUrl] = useState('');
  const [parameters, setParameters] = useState({});
  const [taskStatus, setTaskStatus] = useState('not_started');
  const [videoUrl, setVideoUrl] = useState(null);
  const location = useLocation();
  const [executionData, setExecutionData] = useState(null);

  // Simplified fetchExecutionData
  const fetchExecutionData = useCallback(async (sessionId) => {
    try {
      const data = await loadSessionExecutionData(sessionId);
      setExecutionData(data);
      setTaskStatus(data.status);
      return data.status;
    } catch (error) {
      console.error('Failed to load execution data:', error);
      return 'error';
    }
  }, []);

  // New polling effect to replace WebSocket
  useEffect(() => {
    const sessionId = parameters.session_id;
    if (!sessionId) return;

    // Initial fetch
    fetchExecutionData(sessionId);

    // Set up polling if status isn't final
    const pollInterval = setInterval(async () => {
      const status = await fetchExecutionData(sessionId);
      if (status === 'complete' || status === 'failed') {
        clearInterval(pollInterval);
      }
    }, 5000);

    return () => clearInterval(pollInterval);
  }, [parameters.session_id, fetchExecutionData]);

  // Modify the main effect to remove WebSocket setup
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedUrl = searchParams.get('viewer_url');
    const encodedParams = searchParams.get('params');

    if (encodedUrl) {
      try {
        const decoded = atob(encodedUrl);
        setDecodedUrl(decoded);
      } catch (e) {
        console.error('Failed to decode URL:', e);
      }
    }

    if (encodedParams) {
      try {
        const decodedParams = JSON.parse(atob(encodedParams));
        setParameters(decodedParams);
      } catch (e) {
        console.error('Failed to decode parameters:', e);
      }
    }
  }, [location]);
    
 
  const urlStart = "https://www.browserbase.com/devtools/inspector.html?wss=connect.browserbase.com/"

  const checkForRecording = useCallback(async (sessionId) => {
    const recordingUrl = `https://kura-demo-videos.s3.us-west-2.amazonaws.com/agent-recordings/${sessionId}.webm`;
    try {
      // Create a new video element to test loading
      const video = document.createElement('video');
      
      // Create a promise that resolves when the video can play
      const canPlay = new Promise((resolve, reject) => {
        video.onloadedmetadata = () => resolve(true);
        video.onerror = () => reject(false);
      });
      
      // Set the source and start loading
      video.src = recordingUrl;
      
      // Wait for either success or failure
      const result = await canPlay;
      if (result) {
        return recordingUrl;
      }
    } catch (error) {
      return null;
    }
    return null;
  }, []);

  // Simplify the useEffect to just poll
  useEffect(() => {
    const sessionId = parameters.session_id;
    if (!sessionId) {
      return;
    }

    // Check immediately
    checkForRecording(sessionId).then(recordingUrl => {
      if (recordingUrl) {
        setVideoUrl(recordingUrl);
        return; // Don't set up polling if we found it immediately
      }

      // Set up polling if not found immediately
      const pollInterval = setInterval(async () => {
        const recordingUrl = await checkForRecording(sessionId);
        if (recordingUrl) {
          setVideoUrl(recordingUrl);
          clearInterval(pollInterval);
        }
      }, 5000);

      return () => clearInterval(pollInterval);
    });
  }, [parameters.session_id, checkForRecording]);

  

  // Add new ExecutionDataDisplay component
  const ExecutionDataDisplay = () => {
    const [isExpanded, setIsExpanded] = useState(true);  // Add state for collapse

    if (!executionData) return null;

    return (
      <div className="mb-8 bg-white rounded-xl p-6 shadow-sm border border-gray-100">
        {/* Clickable header with arrow */}
        <div 
          className="flex justify-between items-center cursor-pointer"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <h2 className="text-2xl font-bold text-gray-900">Execution Details</h2>
          <svg 
            className={`w-6 h-6 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </div>
 
            <div className="mb-6 grid grid-cols-2 gap-4 mt-4">
              <div className={`p-4 rounded-lg ${
                executionData.status.toLowerCase() === 'running' ? 'bg-yellow-50' :
                executionData.status.toLowerCase() === 'failed' ? 'bg-red-50' :
                executionData.status.toLowerCase() === 'complete' ? 'bg-green-50' :
                'bg-gray-50'
              }`}>
                <span className="font-semibold">Status:</span>
                <span className="ml-2">
                  {executionData.status.charAt(0).toUpperCase() + executionData.status.slice(1).toLowerCase()}
                </span>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <span className="font-semibold">Duration:</span>
                <span className="ml-2">
                  {executionData.end_time 
                    ? `${((executionData.end_time - executionData.start_time)).toFixed(2)}s`
                    : 'In Progress'}
                </span>
              </div>
            </div>

          {/* Output Display */}
          {isExpanded && (
            executionData.output && (
              <div className="mt-4">
                  <h3 className="text-xl font-semibold text-gray-900 mb-3">Output</h3>
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <pre className="whitespace-pre-wrap text-sm">
                      {typeof executionData.output === 'object'
                        ? JSON.stringify(executionData.output, null, 2)
                        : executionData.output}
                    </pre>
                  </div>
                </div>
            ))}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-white">
      <Navigation />
      
      <main className="max-w-6xl mx-auto px-4 sm:px-6 py-8">
        {/* Your existing warning message */}
        {(!parameters.agent_id || !parameters.stream_type) && (
          <div className="mb-8 bg-red-50 rounded-xl p-6 shadow-sm border border-red-100">
            <p className="text-red-600 font-semibold">Invalid parameters provided</p>
          </div>
        )}

        {/* Agent Title and ID Display */}
        {(parameters.agent_title || parameters.agent_id) && (
          <div className="mb-8 bg-blue-50 rounded-xl p-6 shadow-sm border border-blue-100">
            {parameters.agent_title && (
              <h1 className="text-3xl font-bold text-gray-900 mb-2">{parameters.agent_title}</h1>
            )}
            {parameters.agent_id && (
              <p className="text-gray-600">Agent ID: {parameters.agent_id}</p>
            )}
          </div>
        )}

        {/* Parameters Display */}
        <div className="mb-8 bg-gray-50 rounded-xl p-6 shadow-sm border border-gray-100">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Agent Parameters</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {Object.entries(parameters)
              .filter(([key]) => !['agent_title', 'agent_id', 'stream_type', 'session_id'].includes(key))
              .map(([key, value]) => (
                <div key={key} className="bg-white p-4 rounded-lg shadow-sm">
                  <span className="font-semibold text-gray-700">{key}:</span>
                  <span className="ml-2 text-gray-600">{value}</span>
                </div>
              ))}
          </div>
        </div>

        <ExecutionDataDisplay />

        {/* Website Display */}
        <div className="mb-12">
          {videoUrl || taskStatus === 'completion' ? (
            <div className="rounded-xl overflow-hidden shadow-lg border border-gray-100">
              {videoUrl ? (
               <div className="rounded-xl overflow-hidden shadow-lg border border-gray-100">
               <div className="bg-gray-50 p-4 border-b border-gray-100">
                 <h2 className="text-xl font-semibold text-gray-900">Video playback for a completed run</h2>
               </div>
               <video 
                 controls 
                 className="w-full h-[800px] object-contain bg-black"
                 src={videoUrl}
                 autoPlay
                  />
                </div>
              ) : (
                <div className="w-full h-[800px] bg-gray-50 flex flex-col items-center justify-center">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mb-4"></div>
                  <p className="text-gray-600 font-medium">Video Upload in Progress...</p>
                  <p className="text-gray-500 text-sm mt-2">This may take a few moments</p>
                </div>
              )}
            </div>
          ) : (
            <>
              {parameters.stream_type === "bb" && decodedUrl && (
                <div className="rounded-xl overflow-hidden shadow-lg border border-gray-100">
                  <iframe
                    src={urlStart + decodedUrl}
                    className="w-full h-[800px]"
                    title="Agent Viewer"
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                  />
                </div>
              )}

              {parameters.stream_type === "native" && (
                <VNCViewer 
                  url={decodedUrl}
                  authToken={parameters.auth_token}
                />
              )}
            </>
          )}
        </div>

        {!decodedUrl && (
          <div className="text-center py-12">
            <p className="text-gray-600">No URL provided or invalid encoding</p>
          </div>
        )}
      </main>
    </div>
  );
};

export default AgentViewer;
