const AIBenchmarkScale = () => {
    const systems = [
      { name: 'Kura', percentage: 87, labelPos: 'down', percPos: 'down' },
      { name: "Claude's Computer Use Demo", percentage: 56, labelPos: 'down', percPos: 'down' },
      { name: 'WILBUR', percentage: 52, labelPos: 'up', percPos: 'up' },
      { name: 'Agent E', percentage: 73, labelPos: 'up', percPos: 'up' }
    ].sort((a, b) => a.percentage - b.percentage);
  
    const renderMarkers = () => {
      return (
        <div className="absolute w-full h-full flex justify-end">
          <div className="relative flex flex-col items-center">
            <div className="h-4 sm:h-5 md:h-8 w-px bg-gray-300" />
            <div className="flex items-center mt-1">
              <span className="absolute right-0 translate-x-full pl-2 text-[10px] sm:text-xs font-medium text-red-500 text-center">
                Web<br />Agent<br />Accuracy
              </span>
              <span className="text-[10px] sm:text-xs text-gray-500 font-medium">100%</span>
            </div>
          </div>
        </div>
      );
    };
  
    const kuraPercentage = systems.find(s => s.name === 'Kura').percentage;
  
    return (
      <div className="relative overflow-x-auto" style={{ direction: 'rtl' }}>
        <div className="relative h-[210px] sm:h-80 z-[1]" style={{ 
          minWidth: '550px',
          direction: 'ltr'
        }}>
          <svg
            className="absolute w-36 sm:w-48 md:w-64 h-36 sm:h-64 z-20 -top-[-24px] sm:-top-[40px] -translate-x-[74%] sm:-translate-x-[78%]"
            viewBox="0 0 256 192"
            style={{ 
              left: `${kuraPercentage}%`,
            }}
          >
            <path
              d="M120 60 C 120 80, 115 90, 110 130 L 103 122 M 110 130 L 117 122"
              fill="none"
              stroke="#6B7280"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ 
                strokeDasharray: '4,4',
                filter: 'drop-shadow(1px 1px 1px rgba(0,0,0,0.1))'
              }}
            />
            <text
              x="40"
              y="50"
              className="text-gray-600"
              style={{ 
                fontSize: '14px',
                fontFamily: '"Comic Sans MS", "Chalkboard SE", "Comic Neue", sans-serif',
              }}
            >
              New state of the art!
            </text>
          </svg>
  
          <div className="relative mx-16 sm:mx-24 md:mx-32">
            <div className="absolute w-full h-4 sm:h-5 md:h-6 bg-gradient-to-r from-gray-100 to-gray-50 rounded-lg top-32 shadow-inner" />
            <div className="absolute w-full top-32">{renderMarkers()}</div>
            
            {systems.map((system) => {
              const labelOffset = system.labelPos === 'up' ? '-60px' : '60px';
              const percOffset = system.percPos === 'up' ? '-36px' : '36px';
              
              return (
                <div
                  key={system.name}
                  className="absolute z-10"
                  style={{
                    left: `${system.percentage}%`,
                    top: '127px',
                    transform: 'translateX(-50%)',
                  }}
                >
                  <div className="relative flex flex-col items-center">
                    <div className="h-4 w-4 sm:h-5 sm:w-5 md:h-6 md:w-6 rounded-full border-2 sm:border-3 md:border-4 border-red-500 shadow-sm backdrop-blur-sm" />
                    
                    <div 
                      className="absolute w-full flex justify-center"
                      style={{ top: percOffset }}
                    >
                      <span className="px-1.5 sm:px-2 py-0.5 rounded-full bg-red-50 text-[9px] sm:text-xs font-semibold text-red-500 shadow-sm">
                        {system.percentage}%
                      </span>
                    </div>
                    
                    <div 
                      className="absolute w-full flex justify-center"
                      style={{ top: labelOffset }}
                    >
                      <span className={`px-1.5 sm:px-2 py-0.5 rounded-full bg-gray-50 ${
                        system.name === "Claude's Computer Use" ? 'text-[8px]' : 'text-[10px]'
                      } sm:text-sm font-medium text-gray-700 shadow-sm whitespace-nowrap`}>
                        {system.name}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  export default AIBenchmarkScale;